import React from 'react'
import tw from 'twin.macro'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/Layout'
import CallToAction from '../components/CallToAction'
import Seo from '../components/Seo'

const NotFound = ({ location, data }) => {
  const pageData = data.contentfulNotFoundPage

  return (
    <Layout location={location}>
      <Seo title={'404'} />
      {/* CTA */}
      <CallToAction
        header={pageData.ctaHeadline}
        copy={pageData.ctaSubhead.ctaSubhead}
        ctas={[{ copy: 'Back to Home', destination: '/' }]}
        image={pageData.ctaImage}
      />
    </Layout>
  )
}

export default NotFound

export const pageQuery = graphql`
  {
    contentfulNotFoundPage(pageId: { eq: "404 Page" }) {
      ctaHeadline
      ctaSubhead {
        ctaSubhead
      }
      ctaImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
    }
  }
`
